import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { base_Url } from '../BaseUrl/ApiUrl';
import axios from 'axios';
import { IoIosLogOut } from "react-icons/io";
import { CiWallet } from "react-icons/ci";
import { Grid, Stack, Typography, Avatar, Box, Divider, Button, Card, TableHead, TableBody, TableRow, Table, TableCell } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from '../../components/MainCard';
import user from '../../assets/user.jpg';
import { MdAdminPanelSettings } from "react-icons/md";
import { FaWallet } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import moment from 'moment';
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IoMdCloseCircleOutline } from "react-icons/io";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';




const MyProfile = () => {


    const navigate = useNavigate()
    const student = JSON.parse(localStorage.getItem('Student'));
    const [userData, setUserData] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState('Personal Information');

    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${base_Url}getStudentDataById/${student?.customerid}`);
            if (response.data.status === true) {
                setUserData(response.data.response[0]);
            }
        } catch (error) {
            console.log('getAllClients api status', error);
        }
    };
    const [transaction, setTransaction] = useState([])

    console.log(`🔥🔥🔥 - `, transaction);


    const fetchTransaction = async () => {
        try {
            const response = await axios.post(`${base_Url}/studentPaymentInsights`, {
                "customerid": student?.customerid,

            });
            console.log('response', response)
            if (response.data.response) {
                setTransaction(response?.data?.response)
            }

        } catch (error) {
            console.log(`error in handleFetchData at initial - dashboard - `, error);
        }
    };


    useEffect(() => {
        fetchTransaction()
    }, [])

    useEffect(() => {
        fetchUserData();
    }, []);

    const handleMenuClick = (menu) => {
        setSelectedMenu(menu);
    };

    const handleDownload = async (url) => {
        try {
            const response = await fetch(url, { mode: 'cors' });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = 'document';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error during file download:', error);
        }
    };

    function calculateAge(birthDate) {
        const referenceDate = new Date('2027-06-13');
        const birth = new Date(birthDate);
        let age = referenceDate.getFullYear() - birth.getFullYear();

        if (referenceDate < new Date(referenceDate.getFullYear(), birth.getMonth(), birth.getDate())) {
            age--;
        }

        return age;
    }


    // const handleLogout = () => {
    //     navigate('/')
    //     localStorage.removeItem('Student')

    // }



    const [open, setOpen] = useState(false);

    const [popupData, setpopupData] = useState(null)

    console.log(`popup data- `, popupData);


    const handleClickOpen = (data) => {
        setpopupData(data)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };




    const handleLogout = async () => {

        const paramData = {
            userid: student?.customerid,
            roleid: 2, // 1=>admin,2=>student,3=>Instructor,4=>parent,5-Employee
            config: 1 // 1 - web, 2- app
        }

        await axios.post(`${base_Url}removefcmtoken`, paramData).then((res) => {
            console.log(`fcm removed successfullly`);
            if (res.data.status == true) {
                localStorage.removeItem('Student')
                navigate('/')
            }

        })
            .catch((error) => {
                console.log(`error in reomveFcmToken - `, error);
            })

    };

    return (
        <div>
            <Header />
            <Box sx={{ paddingTop: '120px' }}>
                <Grid gap={2} justifyContent={'center'} container>
                    <Grid item xs={12} md={9}>

                        {/* code starts */}

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <MainCard sx={{ mt: 2, p: 2 }} content={false}>
                                    <Stack direction={'row'} spacing={3} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                        <Avatar sx={{ bgcolor: 'orange', width: 47, height: 47 }} src={user} />
                                        <Stack spacing={0}>
                                            <Typography variant='caption'>Hello,</Typography>
                                            <Typography>{userData?.customername}</Typography>
                                        </Stack>
                                    </Stack>
                                </MainCard>

                                <MainCard sx={{ mt: 2 }} content={false}>
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}
                                        sx={{ cursor: 'pointer', p: 1.2 }}
                                        onClick={() => handleMenuClick('Orders')}
                                        style={{ backgroundColor: selectedMenu === 'Orders' ? '#e0f7fa' : 'transparent' }}
                                    >
                                        <Stack direction={'row'} spacing={4.5} alignItems={'center'}>
                                            <FaWallet style={{ color: '#1976d2', width: '27px', height: '27px' }} />
                                            <Typography sx={{ color: 'grey !important', fontSize: '16px !important' }}>MY ORDERS</Typography>
                                        </Stack>
                                        <IoIosArrowForward style={{ width: '27px', height: '27px', color: 'grey ' }} />
                                    </Stack>
                                    <Divider sx={{ mb: 1 }} />





                                    <Stack direction={'row'} spacing={3} alignItems={'center'} sx={{ cursor: 'pointer', p: 1.4 }}

                                        style={{ backgroundColor: selectedMenu === 'Account Settings' ? '#e0f7fa' : 'transparent' }}
                                    >
                                        <MdAdminPanelSettings style={{ color: '#1976d2', width: '35px', height: '35px' }} />
                                        <Typography sx={{ color: 'grey !important', fontSize: '16px !important' }}>ACCOUNT SETTINGS</Typography>
                                    </Stack>

                                    <Stack spacing={1} sx={{ pl: 8, mt: 1, mb: 1, pl: 1 }} >
                                        <Typography variant='body2'

                                            sx={{
                                                color: 'black !important', fontSize: '14px !important', pl: 1, py: 1,
                                                cursor: 'pointer',
                                                backgroundColor: selectedMenu === 'Personal Information' ? '#e0f7fa' : 'transparent'

                                            }}
                                            onClick={() => handleMenuClick('Personal Information')}
                                            style={{}}
                                        >
                                            Personal Information
                                        </Typography>
                                        <Typography variant='body2'
                                            sx={{
                                                color: 'black !important', fontSize: '14px !important', pl: 1, py: 1,
                                                cursor: 'pointer',
                                                backgroundColor: selectedMenu === 'Parents Details' ? '#e0f7fa' : 'transparent'

                                            }}
                                            onClick={() => handleMenuClick('Parents Details')}

                                        >
                                            Parents Details
                                        </Typography>
                                        {/* <Typography variant='body2'
                                            sx={{
                                                color: 'black !important', fontSize: '14px !important', pl: 1, py: 1,
                                                cursor: 'pointer',
                                                backgroundColor: selectedMenu === 'Documents' ? '#e0f7fa' : 'transparent'

                                            }}
                                            onClick={() => handleMenuClick('Documents')}

                                        >
                                            Documents
                                        </Typography> */}
                                    </Stack>

                                </MainCard>

                                <MainCard sx={{ mt: 2, p: 2 }} content={false}>
                                    <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={() => handleLogout()}>
                                        <IoIosLogOut style={{ color: '#1976d2', width: '35px', height: '35px' }} />
                                        <Typography sx={{ fontSize: '15px !important' }}>Logout</Typography>
                                    </Stack>
                                </MainCard>
                            </Grid>

                            <Grid item xs={12} md={9}>
                                <MainCard sx={{ mt: 2, pb: '30px' }} content={false}>
                                    <Typography sx={{ p: 2 }}>{selectedMenu}</Typography>
                                    <Divider />
                                    {selectedMenu === 'Personal Information' && (
                                        <Grid container justifyContent={'center'} alignItems={'center'} spacing={3} sx={{ p: 2 }}>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Name
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.customername}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Email
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.email}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Phone Number
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.contactno1}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Date of birth
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.dob}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Gender
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.gender}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        City
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.city}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        State
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.state}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Country
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.country}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Zipcode
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.postcode}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Address
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.address}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {selectedMenu === 'Orders' && (
                                        <>
                                            {
                                                transaction[0]?.comboid == 0 && transaction[0]?.courses?.map((items, index) => {
                                                    return <MainCard key={index} sx={{ m: 2 }} content={false}>

                                                        {console.log(`value 1 - `, items)}

                                                        <Grid container justifyContent="space-around" alignItems="center" sx={{ p: 1 }}>
                                                            <Grid item xs={12} md={4} container alignItems="center">
                                                                <Stack direction={'row'} spacing={3} alignItems="center">


                                                                    <img
                                                                        alt='Product'
                                                                        src={items?.image}
                                                                        style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                                                                    />
                                                                    <Typography sx={{ ml: 2 }}>
                                                                        {items?.productname}
                                                                    </Typography>

                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <Typography variant='h5' sx={{ color: 'black !important', textAlign: 'center' }}>
                                                                    ${(()=>{
                                                                        const finalData = Number(items?.price)

                                                                        return Math.round(finalData)
                                                                    })()}
                                                                </Typography>
                                                            </Grid>

                                                            {/* ================================================== 🕒 Profile view course Pending 🕒 ================================== */}

                                                            {/* <Grid item xs={2}>
                                                            <Button onClick={() => handleClickOpen(item)}>View Details</Button>
                                                        </Grid> */}

                                                            {/* ================================================== 🕒 Profile view course Pending 🕒 ================================== */}


                                                            <Grid item xs={12} md={4} container direction="column" alignItems="flex-end">
                                                                <Typography>Purchased on</Typography>
                                                                <Typography variant='caption'>
                                                                    {transaction[0]?.orderdate ? moment(transaction[0]?.orderdate).format('DD-MM-YYYY hh:mm A') : 'Date not available'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Divider />


                                                        <Grid container justifyContent="space-around" alignItems="center" sx={{ p: 1 }}>
                                                            <Grid item xs={12} md={4} container >
                                                                <Stack direction={'column'} spacing={3} alignItems="center">


                                                                    <Typography variant='h6' sx={{ textAlign: 'center' }}>Date and Time</Typography>

                                                                    {
                                                                        items?.datestring && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center !important' }}>
                                                                            {items?.datestring?.map((val) => {

                                                                                return <Typography >
                                                                                    {val}
                                                                                </Typography>

                                                                            })}
                                                                        </Box>
                                                                    }



                                                                </Stack>
                                                            </Grid>
                                                            <Divider orientation="vertical" flexItem />
                                                            <Grid item xs={12} md={3}>
                                                                <Stack direction={'column'} spacing={3} alignItems="center">


                                                                    <Typography variant='h6'>Mode</Typography>

                                                                    <Typography sx={{ ml: 2 }}>
                                                                        {items?.classmode || ''}
                                                                    </Typography>

                                                                </Stack>
                                                            </Grid>

                                                            <Divider orientation="vertical" flexItem />

                                                            {/* ================================================== 🕒 Profile view course Pending 🕒 ================================== */}

                                                            {/* <Grid item xs={2}>
                                                            <Button onClick={() => handleClickOpen(item)}>View Details</Button>
                                                        </Grid> */}

                                                            {/* ================================================== 🕒 Profile view course Pending 🕒 ================================== */}


                                                            <Grid item xs={12} md={4} container direction="column" alignItems="center">
                                                                <Stack direction={'column'} spacing={3} alignItems="center">


                                                                    <Typography variant='h6'>Location</Typography>


                                                                    <Typography sx={{ ml: 2 }}>
                                                                        {items?.location || ''}
                                                                    </Typography>

                                                                </Stack>
                                                            </Grid>
                                                        </Grid>

                                                    </MainCard>
                                                })
                                            }


                                            {
                                                transaction[0]?.comboid != 0 && transaction?.map((item, index) => (

                                                    <MainCard key={index} sx={{ m: 2 }} content={false}>

                                                        {console.log(`value 2 - `, item)}

                                                        <Grid container justifyContent="space-around" alignItems="center" sx={{ p: 1 }}>
                                                            <Grid item xs={12} md={4} container alignItems="center">
                                                                <Stack direction={'row'} spacing={3} alignItems="center">


                                                                    <img
                                                                        alt='Product'
                                                                        src={item?.courses[0]?.image}
                                                                        style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                                                                    />
                                                                    <Typography sx={{ ml: 2 }}>
                                                                        {item?.courses?.map(product => product?.productname).join(', ')}
                                                                    </Typography>

                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={12} md={2}>
                                                                <Typography variant='h5' sx={{ color: 'black !important', textAlign: 'center' }}>
                                                                    ${Math.round(item?.totalamount) ?? 'N/A'}
                                                                </Typography>
                                                            </Grid>

                                                            {/* ================================================== 🕒 Profile view course Pending 🕒 ================================== */}

                                                            <Grid item xs={2}>
                                                                <Button variant='contained' onClick={() => handleClickOpen(item?.courses)}>View Details</Button>
                                                            </Grid>

                                                            {/* ================================================== 🕒 Profile view course Pending 🕒 ================================== */}


                                                            <Grid item xs={12} md={3} container direction="column" alignItems="flex-end">
                                                                <Typography>Purchased on</Typography>
                                                                <Typography variant='caption'>
                                                                    {item?.orderdate ? moment(item.orderdate).format('DD-MM-YYYY hh:mm A') : 'Date not available'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                    </MainCard>
                                                ))
                                            }


                                        </>
                                    )}




                                    {selectedMenu === 'Parents Details' && (


                                        !_.isEmpty(userData?.parendata) ?
                                            <Grid container justifyContent={'center'} alignItems={'center'} spacing={3} sx={{ p: 2 }} >
                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={0.5} >
                                                        <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                            Name
                                                        </Typography>

                                                        <Typography>
                                                            {userData?.parendata?.parentname}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>






                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={0.5}>
                                                        <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                            Email
                                                        </Typography>
                                                        <Typography>
                                                            {userData?.parendata?.authname}
                                                        </Typography>

                                                    </Stack>
                                                </Grid>

                                                <Divider />

                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={0.5}>
                                                        <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }} >
                                                            Mobile No
                                                        </Typography>

                                                        <Typography>
                                                            {userData?.parendata?.contactno}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={0.5}>
                                                        <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }} >
                                                            Relation
                                                        </Typography>

                                                        <Typography>
                                                            {userData?.parendata?.parentrelation}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>


                                            </Grid>

                                            :
                                            <Typography>No Information provided ...</Typography>




                                    )}
                                    {/* {selectedMenu === 'Documents' && (
                                        <Typography sx={{ p: 2 }}>Documents content here...</Typography>
                                    )} */}
                                </MainCard>
                            </Grid>
                        </Grid>




                    </Grid>
                </Grid>
            </Box>


            {/* combo dialog */}

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">

                    <Stack direction={'row'} justifyContent={'end'}>
                        <Tooltip title="Close">
                            <IconButton color='error' onClick={() => setOpen(false)}>
                                <IoMdCloseCircleOutline />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                </DialogTitle>
                <DialogContent>

                    <Table container>

                        <TableHead>

                            <TableRow>

                                <TableCell>
                                    #
                                </TableCell>
                                <TableCell>
                                    Product Name
                                </TableCell>
                                <TableCell>
                                    Date and Time
                                </TableCell>
                                <TableCell>
                                    mode
                                </TableCell>
                                <TableCell>
                                    Location
                                </TableCell>

                            </TableRow>


                        </TableHead>

                        <TableBody>

                            {
                                popupData && popupData?.map((data, index) => {
                                    return <TableRow key={index + 1}>

                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{data?.productname}</TableCell>

                                        <TableCell>
                                            {
                                                data?.datestring && data?.datestring?.map((val) => {
                                                    return <Typography>{val}</Typography>
                                                })
                                            }
                                        </TableCell>

                                        <TableCell>{data?.classmode}</TableCell>
                                        <TableCell>{data?.location}</TableCell>

                                    </TableRow>
                                })
                            }

                        </TableBody>

                    </Table>

                </DialogContent>
            </Dialog>

            {/* combo dialog */}


        </div>
    );
}

export default MyProfile;
