import { Box, CardMedia, Container, Grid, Typography, Stack } from '@mui/material'
import React, { useState } from 'react'

import ProminentBg from '../../assets/images/driving-lesson/drvng_lessons_prominent_bg.png';
import DljlFeaImg from '../../assets/images/driving-lesson/DljlFeaImg.png';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import pickup from '../../assets/images/driving-lesson/pickup.gif';
import HoverVideoPlayer from 'react-hover-video-player';
// import HoverVideoPlayer from 'react-hover-video-player';

const styles = {
    featureBg: {
        flexGrow: 1, background: `url(${ProminentBg}) no-repeat top center`, backgroundSize: '100% 100%', padding: '60px 0px 60px',
        '@media (max-width: 900px)': {
            padding: '50px 0px 50px 16px',
        }
    },
    featureGif: {
        width: '70px', height: '70px',
    },
    featureBoxCon: {
        background: '#D7E7FF', display: 'flex', gap: '10px', padding: '24px 20px', borderRadius: '6px',
        '&:hover': { background: '#fff', },
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        },

    },

}

const featureBox = [
    {
        featureBoxGif: pickup,
        tittle: "Prepare Teens for Any Driving Situation",
        description: "Our comprehensive curriculum is designed to equip teens with the skills and knowledge needed to handle various driving scenarios confidently and safely.",

    },

]


const DljlFeatures = () => {


    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    return (
        <>

            <Box sx={styles.featureBg} >

                <Container >
                    <Grid container spacing={2}>

                        <Grid item md={6}>
                            {/* <Typography sx={{ color: '#fff !important', marginBottom: '10px', }}>Features</Typography> */}
                            <Typography variant='h4' sx={{ color: 'var(--yellow) !important', }}>Significant Aspects of the Course</Typography>
                            <Typography variant='h4' sx={{ color: '#fff !important', mt: '10px', mb: 5 }}>Interactive Learning Experience</Typography>



                            {featureBox.map((featureBoxItem, index) => (
                                <div item md={5.9} key={index} sx={{ padding: '0px !important', display: 'grid', paddingTop: '20px !important' }}>
                                    <Stack direction='row' sx={{
                                        background: '#D7E7FF', display: 'flex', gap: '10px', padding: '24px 20px', borderRadius: '6px',
                                        '&:hover': { background: '#fff', },
                                        '@media (max-width: 600px)': {
                                            flexDirection: 'column',
                                        },

                                    }}>

                                        <CardMedia component="img" alt="program Image" src={featureBoxItem.featureBoxGif} sx={styles.featureGif} />
                                        <div>
                                            <Typography variant="h2" sx={{ marginBottom: '10px' }}>{featureBoxItem.tittle}</Typography>
                                            <Typography component="f15w500">{featureBoxItem.description}</Typography>
                                        </div>

                                    </Stack>
                                </div>
                            ))}
                        </Grid>

                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'end', }}>
                            <Grid sx={{ position: 'relative' }}>
                                {/* <img src={DljlFeaImg} style={{maxWidth:'100%',}} />
                        <PlayArrowIcon className='playbut'  sx={{position:'absolute',}} /> */}

                                {/* <video controls width="95%" height={'95%'}>
                                    <source src={"https://webnox.blr1.digitaloceanspaces.com/1ACT_WEBSITE_VIDEOS/Joshua%20law.mp4"}
                                        type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video> */}

                                {/* <div
                                    onClick={() => setIsVideoPlaying(!isVideoPlaying)}
                                    style={{ position: 'relative', width: '100%', height: '100%' }}
                                >
                                    <HoverVideoPlayer
                                        videoSrc="https://webnox.blr1.digitaloceanspaces.com/1ACT_WEBSITE_VIDEOS/Joshua%20law.mp4"
                                        pausedOverlay={

                                            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                                <div style={{ position: "absolute", background: "black", width: '100%', height: "100%", opacity: ".3" }} />

                                                <img
                                                    src="https://oneactfilestorage.s3.amazonaws.com/driving_school/WebsiteVideos/Joshuas+law.jpg"
                                                    alt="dd"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',

                                                    }}
                                                />
                                                <PlayArrowIcon
                                                    className='playbut'
                                             
                                                />
                                            </div>
                                        }
                                        loadingOverlay={
                                            <div className="loading-overlay">
                                                <div className="loading-spinner" />
                                            </div>
                                        }
                                        controls
                                        focused={isVideoPlaying}
                                        disableDefaultEventHandling
                                        controlsList="nodownload"
                                        muted={false}
                                    />
                                </div> */}


                                <img
                                    src="https://oneactfilestorage.s3.amazonaws.com/driving_school/WebsiteVideos/Joshuas+law.jpg"
                                    alt="dd"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',

                                    }}
                                />

                            </Grid>
                        </Grid>

                    </Grid>

                    {/* <Grid container spacing={2} sx={{ marginTop: '40px', display: 'flex', gap: '14px', justifyContent: 'center', }}>

                        {featureBox.map((featureBoxItem, index) => (
                            <Grid item md={5.9} key={index} sx={{ padding: '0px !important', display: 'grid' }}>
                                <Grid sx={styles.featureBoxCon}>

                                    <CardMedia component="img" alt="program Image" src={featureBoxItem.featureBoxGif} sx={styles.featureGif} />
                                    <Grid>
                                        <Typography variant="h2" sx={{ marginBottom: '10px' }}>{featureBoxItem.tittle}</Typography>
                                        <Typography component="f15w500">{featureBoxItem.description}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))}

                    </Grid> */}

                </Container>
            </Box>


        </>
    )
}

export default DljlFeatures
