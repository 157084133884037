
import React, { useMemo } from 'react';
import { Slide, DialogTitle, DialogContent, Typography, DialogActions, Checkbox, Button, FormControlLabel, Dialog, Stack, IconButton, Box, useMediaQuery } from '@mui/material';

import { IoMdCloseCircleOutline } from "react-icons/io";


import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { Padding } from '@mui/icons-material';
import Lottie from 'lottie-react';
import free from '../../assets/lottie/free2.json'
import groupImage from '../../assets/combo/christmas.jpg'
import groupImagembl from '../../assets/combo/christmasmbl.jpg'
import NewOffBanner from './newOffBanner';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AnimatedDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        animation: `$slideIn 5s`,
        "@keyframes slideIn": {
            "0%": {
                transform: "translateX(-100%)",
            },
            "100%": {
                transform: "translateX(0)",
            },
        },
        // padding: '2mm !important',
        // border: '2px solid black !important',
    },
});



const UpsellingDialog = ({
    open,
    handleClose,
    selectedBTWHour,
    dontShowAgain,
    handleCheckboxChange,
}) => {

    // Memoize dialog content based on `selectedBTWHour` and `dontShowAgain`
    const dialogContent = useMemo(() => {
        if (selectedBTWHour === 2 || selectedBTWHour === 4) {
            return (
                <Typography>
                    Unlock Your Full Potential! Upgrade to 6 hours of BTW classes and grab
                    Drivers Education for just $95 <br />(save $29!). More hours, more confidence,
                    and bigger savings – get started today!
                </Typography>
            );
        } else if (selectedBTWHour === 8 || selectedBTWHour === 10) {
            return (
                <Typography>
                    Maximize your learning! Upgrade to 12 hours of BTW classes and get Defensive Driving absolutely FREE!
                    Don’t miss out on this exclusive offer!
                </Typography>
            );
        } else {
            return null;
        }
    }, [selectedBTWHour]);


    const navigate = useNavigate()


    const isMobileView = useMediaQuery('(max-width:600px)');



    return (
        <AnimatedDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="custom-dialog-title"
            TransitionComponent={Transition}
            transitionDuration={{ enter: 200, exit: 200 }}
            keepMounted
            fullWidth
            maxWidth='lg'
        >
            <div
            // style={{
            //     borderRadius: '5px',
            //     background: `url(${isMobileView ? groupImagembl : groupImage})`,
            //     backgroundPosition: 'center',
            //     backgroundRepeat: 'no-repeat',
            //     backgroundSize: 'cover'
            // }}
            >
                {/* <DialogTitle id="custom-dialog-title">


                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                        <Typography sx={{ color: 'white !important', textAlign: 'center', flexGrow: 1 }} variant="h5">
                            Limited Time Holiday Offer!
                        </Typography>

                        <IconButton sx={{ fontSize: '30px', color: 'black', background: 'white !important' }} variant="outlined" color='error' onClick={handleClose} >
                            <IoMdCloseCircleOutline sx={{ fontSize: '30px' }} color='red' />
                        </IconButton>

                    </Stack>
                </DialogTitle> */}
                <DialogContent sx={{ padding: 1 }}>


                    {/* <Box sx={{ px: 3, py: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                        <Typography sx={{ color: 'white !important', textAlign: 'center' }}>
                            <span style={{ verticalAlign: 'middle' }}>
                                <Lottie animationData={free} loop={true} style={{ width: '40px', height: '40px', display: 'inline-block', color: 'black' }} />
                            </span> Road Test with a purchase of <b style={{ textDecoration: 'underline' }}>10 Hours or More</b> Driving Lessons
                        </Typography>
                      
                        <br />
                        <Typography sx={{ color: 'yellow !important', textAlign: 'center' }}>
                            Driving Lessons must be purchased between <b style={{ textDecoration: 'underline' }}>November 15 through December 31,2024</b>
                        </Typography>
                        <br />
                        <Typography sx={{ color: 'white !important', textAlign: 'center' }}>
                            Purchase Driving Lessons by clicking below and <b>Call 770-274-4223 </b>to avail Road Test for Free !
                        </Typography>
                        <br />
                        {dialogContent}
                        <Button variant="contained" color="error" onClick={() => navigate("/schedule-driving-lessons-2/2")} sx={{ mt: 2 }}>BUY NOW</Button>
                    </Box> */}


                    <NewOffBanner
                        handleClose={handleClose}
                    />

                </DialogContent>

                {/* <DialogActions> 
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={dontShowAgain}
                            onChange={handleCheckboxChange}
                        />
                    }
                    label="Do not show again"
                /> 
                <Button variant="contained" color="error" onClick={handleClose}>
                    Close
                </Button>
                </DialogActions> */}

            </div>
        </AnimatedDialog>
    );
};

export default UpsellingDialog;
