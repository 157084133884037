import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'

import RoadTestingBannerBg from '../../assets/images/roadTesting/RoadTestingBanner.png'
import conex from '../../assets/images/driving-lesson/conex.png'

import Register from '../Register/Register'
import axios from 'axios'
import { base_Url } from '../BaseUrl/ApiUrl'
import { googleAnalyticsFunction } from '../googleanalytics/googleanalytics'
import NewUserRegister from '../Register/NewUserRegister'


const styles = {
  rtBanner: {
    pt: "150px", pb: "50px", background: `url(${RoadTestingBannerBg})`, backgroundSize: '100% 100%',
    '@media (max-width: 900px)': {
      backgroundSize: 'auto',
    }
  }
}


const RtBanner = () => {

  const [roadTesting, setRoadTesting] = useState([])
  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllCourse`, {
        type: ''
      })
      if (response.data.status) {
        console.log('response @@@', response?.data?.response)

        const course5 = response?.data?.response[4]
        setRoadTesting(course5)

      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPackages()
  }, [])
  console.log('roadTesting', roadTesting)



  const [registerStatus, setRegisterStatus] = useState(false)

  const [finalData, setFinalData] = useState({})


  const [newUser, setNewUser] = useState(false)


  const fetchcheckPackageisExist = async (packageid) => {

    const isLoggedIn = localStorage.getItem('Student')

    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, {
        packageid: packageid
      })

      if (response.data.message === 'success') {
        // console.log('response', response?.data?.response[0])


        const dataForGTM = response?.data?.response

        const courseData = dataForGTM?.packages?.map((val) => {
          return {
            productName: val?.packagename,  
            productPrice: val?.packageamount
          }
        })

        console.log('response roadTest finalData - ', courseData)

        googleAnalyticsFunction(dataForGTM, courseData)

        setFinalData(response?.data?.response)
        // setRegisterStatus(true)

        // if (isLoggedIn == null) {
          setNewUser(true)
        // }
        // else {
        //   setRegisterStatus(true)
        // }


      } else {
        setFinalData({})
      }
    } catch (error) {
      console.log(error)
    }
  }






  return (
    <>

      <Box sx={styles.rtBanner} >
        <Container>
          <Grid container spacing={2}>

            <Grid item md={6}>
              <Grid sx={{}}>
                <Typography variant='h5' sx={{ fontWeight: '400 !important', lineHeight: '1.5', color: '#fff !important', mb: '10px' }}>Building Road-ready Confidence</Typography>
                <Typography variant='h1' sx={{ color: 'var(--yellow) !important', }}>ONSITE ROAD TESTING</Typography>

                {/* <Grid sx={{ margin: '40px 0px 0px', }}>
                  <Grid sx={{ display: 'flex', marginBottom: '20px', }}><img src={conex} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp; <Typography color="#fff !important"><Typography color="#fff !important" >We Don't Just Teach Driving, We Build Confident Drivers…………</Typography></Typography></Grid>
                  <Grid sx={{ display: 'flex', marginBottom: '20px', }}><img src={conex} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp; <Typography color="#fff !important"><Typography color="#fff !important" ></Typography>We'll equip you with the knowledge and practice you need to become a licensed driver.</Typography></Grid>
                </Grid> */}
                {/* {roadTesting?.courseDetails?.map((program, index) => ( */}
                <Button variant="outlined" className='greenButAni' onClick={() => fetchcheckPackageisExist([roadTesting?.courseDetails[0]?.packageid])}  >
                  Register Now
                </Button>

                <NewUserRegister
                  openStatus={newUser}
                  setRegisterStatus={setNewUser}
                  finalData={finalData}
                />
                {/* ))} */}
                {/* <Register openStatus={registerStatus} setRegisterStatus={setRegisterStatus} finalData={finalData} btw={true} /> */}

              </Grid>
            </Grid>

            <Grid item md={6}></Grid>

          </Grid>
        </Container>
      </Box>


    </>
  )

}

export default RtBanner
