import { Box, CardMedia, Container, Grid, Typography, Stack, Divider, Skeleton } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ProminentBg from '../../assets/images/driving-lesson/drvng_lessons_prominent_bg.png'
import ProminentRhtImg from '../../assets/images/driving-lesson/Prominent-rht-img.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HoverVideoPlayer from 'react-hover-video-player';

import pickup from '../../assets/images/driving-lesson/pickup.gif'
import { keyframes } from '@mui/system';
const styles = {
    featureBg: {
        flexGrow: 1, background: `url(${ProminentBg}) no-repeat top center`, backgroundSize: '100% 100%', padding: '60px 0px 60px',
        '@media (max-width: 900px)': {
            padding: '50px 0px 50px 16px',
        }
    },
    featureGif: {
        width: '70px', height: '70px',
    },
    featureBoxCon: {
        background: '#D7E7FF', display: 'flex', gap: '10px', padding: '24px 20px', borderRadius: '6px',
        '&:hover': { background: '#fff', },
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        },

    },
}


const featureBox = [
    {
        featureBoxGif: pickup,
        tittle: "Pickup and Drop",
        description: "Flexible online scheduling and free Pickup and Drop off within our radius!",

    },
    {
        featureBoxGif: pickup,
        tittle: "Expert Instruction",
        description: "Learn from licensed professionals with years of experience, ensuring Top-notch Driving Lessons and safety.",

    },
    {
        featureBoxGif: pickup,
        tittle: "Service Radius",
        description: "Our Service Area covers a 15-mile Radius Beyond each of our convenient locations.",

    },
]


const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Prominent = () => {

    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    // Intersection Observer to trigger animation when the section comes into view
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);


    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    return (
        <>
            <Box sx={styles.featureBg} >
                <Container>
                    <Grid container spacing={4} alignItems={'center'}>

                        <Grid item md={6} sx={{ mt: 1 }}>
                            {/* <Typography sx={{ color: '#fff !important', marginBottom: '10px', }}>Features</Typography> */}
                            <Typography variant='h4' sx={{ color: 'var(--yellow) !important', }}>Prominent Characteristic Features</Typography>
                            <br />
                            {/* <br /> */}
                            <Typography variant='h4' sx={{ color: 'white !important', }}>Get on the Road with Confidence!</Typography>
                        </Grid>

                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'end', }}>
                            <Grid >

                                {/* <video controls width="90%" height={'90%'}>
                                    <source src={"https://webnox.blr1.digitaloceanspaces.com/1ACT_WEBSITE_VIDEOS/Behind%20The%20Wheel_Final%20(2).mp4"}
                                        type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video> */}
                                {/* <PlayArrowIcon  sx={{ position: 'absolute', }}/>


                                <div

                                    onClick={() => setIsVideoPlaying(!isVideoPlaying)}
                                    sx={{ position: 'relative' }}
                                >
                                    <HoverVideoPlayer
                                        videoSrc="https://webnox.blr1.digitaloceanspaces.com/1ACT_WEBSITE_VIDEOS/Behind%20The%20Wheel_Final%20(2).mp4"
                                        pausedOverlay={
                                            <img
                                                src="https://oneactfilestorage.s3.amazonaws.com/driving_school/WebsiteVideos/Driving+Lesson+2.jpg"
                                                alt="dd"
                                                style={{
                                                    // Make the image expand to cover the video's dimensions
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    // position: 'absolute'
                                                }}
                                            />
                                        }
                                        loadingOverlay={
                                            <div className="loading-overlay">
                                                <div className="loading-spinner" />
                                            </div>
                                        }
                                        controls
                                        focused={isVideoPlaying}
                                        disableDefaultEventHandling
                                        controlsList="nodownload"

                                    />
                                </div> */}




                                <div
                                    onClick={() => setIsVideoPlaying(!isVideoPlaying)}
                                    style={{ position: 'relative', width: '100%', height: '100%' }}
                                >

                                    {/* {isLoading && ( // Show skeleton loader while loading
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100%"
                                            animation="wave"
                                            style={{ position: 'absolute', top: 0, left: 0 }}
                                        />
                                    )} */}
                                    {/* <HoverVideoPlayer
                                        videoSrc="https://webnox.blr1.digitaloceanspaces.com/1ACT_WEBSITE_VIDEOS/Behind%20The%20Wheel_Final%20(2).mp4"
                                        pausedOverlay={

                                            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                                <div style={{ position: "absolute", background: "black", width: '100%', height: "100%", opacity: ".3" }} />

                                                <img
                                                    src="https://oneactfilestorage.s3.amazonaws.com/driving_school/WebsiteVideos/Driving+Lesson.jpg"
                                                    alt="dd"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',

                                                    }}
                                                />
                                                <PlayArrowIcon
                                                    className='playbut'

                                                />
                                            </div>
                                        }
                                        loadingOverlay={
                                            <div className="loading-overlay">
                                                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                                                    <Skeleton variant="rectangular" width="80%" height="80%" animation="wave" />
                                                </Box>
                                            </div>
                                        }
                                        controls
                                        focused={isVideoPlaying}
                                        disableDefaultEventHandling
                                        controlsList="nodownload"
                                        muted={false}
                                        
                                    /> */}




                                    <img
                                        src="https://oneactfilestorage.s3.amazonaws.com/driving_school/WebsiteVideos/Driving+Lesson.jpg"
                                        alt="dd"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',

                                        }}
                                    />
                                </div>




                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2} sx={{ marginTop: '40px', display: 'flex', gap: '14px', }}>

                        {featureBox.map((featureBoxItem, index) => (
                            <Grid item md={3.9} key={index} sx={{ padding: '0px !important', display: 'grid' }}>
                                <Grid sx={styles.featureBoxCon}>

                                    <CardMedia component="img" alt="program Image" src={featureBoxItem.featureBoxGif} sx={styles.featureGif} />
                                    <Grid>
                                        <Typography variant="h2" sx={{ marginBottom: '10px' }}>{featureBoxItem.tittle}</Typography>
                                        <Typography component="f15w500">{featureBoxItem.description}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))}

                    </Grid>

                </Container>
            </Box>

        </>
    )
}

export default Prominent
